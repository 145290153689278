<template>
  <div id="editCoordDialog">
    <el-dialog
      title="点位修改"
      :visible.sync="show"
      @opened="openDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="reset"
    >
      <el-form :model="form" label-width="90px">
        <el-form-item label="已选地址：">
          {{ form.address }}
        </el-form-item>
        <el-form-item label="已选经度：">
          {{ form.longitude }}
        </el-form-item>
        <el-form-item label="已选维度：">
          {{ form.latitude }}
        </el-form-item>
        <el-form-item style="margin-bottom: 10px;">
          <el-button
            type="primary"
            @click="saveBtn"
            :loading="loading"
            size="mini"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
      <gaode-map
        @mapClick="mapClick"
        :initCoord="defaultCoord"
        height="400px"
      />
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    gaodeMap: () => import("@/components/gaode-map.vue"),
  },
  props: ["getInfo"],
  data() {
    return {
      show: false,
      loading: false,
      form: {
        address: "",
        longitude: "",
        latitude: "",
      },
      defaultCoord: {
        lng: "",
        lat: "",
      },
    };
  },

  methods: {
    openDialog() {
      if (this.form.longitude && this.form.latitude) {
        Object.assign(this.defaultCoord, {
          lng:  this.form.longitude,
          lat:  this.form.latitude,
        });
      }
    },
    reset() {
      this.$set(this, "defaultCoord", {
        lng: "",
        lat: "",
      });
    },
    mapClick(params) {
      Object.assign(this.form, {
        address: params.address,
        longitude: params.longitude,
        latitude: params.latitude,
      });
    },
    async saveBtn() {
      try {
        this.loading = true;
        let res = await this.$http.post("/parking/edit", {
          parkingLotLng: this.form.longitude,
          parkingLotLat: this.form.latitude,
          parkingLotAddress: this.form.address,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          this.$message.success("编辑成功!");
          this.show = false;
          this.getInfo();
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#editCoordDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 850px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
